<template>
    <div>
        <h1>Company Invoice Statements</h1>
    </div>
</template>

<script>
    export default {
        name: 'CompanyInvoiceStatements'
    };
</script>
